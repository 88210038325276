
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { dyUserList, dyUserCancel } from "@/api/request/blogger";

//组件
@Component({
  name: "DYUser",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    page_size: 20,

    //时间数据
    time: [],
    end_time: "",
    start_time: "",

    //临时数据
    user_id_str: "",
    blogger_id_str: "",

    //请求参数
    user_id: 0,
    blogger_id: 0,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = this.listQuery.time[1];
      this.listQuery.start_time = this.listQuery.time[0];
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await dyUserList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理取消关系
  private handleCancel(user_id: number, blogger_id: number): void {
    //显示提示
    this.$confirm(`确定取消关系？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求取消
        const data: any = await dyUserCancel({ user_id: user_id, blogger_id: blogger_id });

        //取消成功
        if (data.status == 0) {
          //显示提示
          this.$message.success("取消成功");

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
